import React, {useEffect, useState} from 'react';
import './App.css';

import btn_here from "./resources/btn_tall.png";

function App() {
  const [ouroText, setOuroText] = useState(null);
  const [redirectText, setRedirectText] = useState(null);
  const [countdownText, setCountdownText] = useState(null);
  const [countdown, setCountdown] = useState(-2);
  const [buttonText, setButtonText] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setOuroText(
        <div className='title-ouroboros-div fade-in-text'>
          <label className='title-ouroboros'>OURO GAMING</label>
        </div>
      )
    }, 2000);
    setTimeout(() => {
      setRedirectText(
        <div className='title-redirecting-div fade-in-text'>
          <label className='title-redirecting'>You will be redirected to the blockchain in</label>
        </div>
      )
      setCountdown(4);
    }, 4000);
  }, []);

  useEffect(() => {
    if(countdown > -1){
      let _c = countdown;
      setTimeout(() => {
        setCountdown(_c - 1);
        setCountdownText(
          <div className='title-redirecting-div fade-in-text'>
            <label className='title-redirecting'>{countdown}</label>
          </div>
        );
      }, 1000);
    } else {
      if(countdown === -1){
        window.location.href = "https://win5b-taaaa-aaaaj-aedda-cai.raw.ic0.app/";
        setTimeout(() => {
          setButtonText(
            <div className='title-redirecting-div fade-in-text'>
              <label className='title-nothing'>If nothing happened, please click</label>
              <div className='btn-div'>
                <div className='btn-here' style={{background: `url(${btn_here})`}} onClick={() => { redirectToBlockchain(); }}>HERE</div>
              </div>
            </div>
          );
        }, 2000);
      }
    }
  }, [countdown]);

  const redirectToBlockchain = () => {
    window.location.href = "https://win5b-taaaa-aaaaj-aedda-cai.raw.ic0.app/";
  };

  return (
    <div className="main-div">
      <div className='title-welcome-div fade-in-text'>
        <label className='title-welcome'>WELCOME TO</label>
      </div>
      {ouroText}
      {redirectText}
      {countdownText}
      {buttonText}
    </div>
  );
}

export default App;
